<template>
    <div class="activies">
        <common-search-bar
            ref="searchBar"
            @change="handleChange"
        ></common-search-bar>
        <div class="list-container">
            <div class="list" v-if="list.length">
                <div class="item" v-for="(item, idx) in list" :key="item._id">
                    <div class="img-container">
                        <img
                            class="item-img"
                            alt=""
                            v-real-img="item.cover.src"
                        />
                        <Icon
                            @click="goTo(item)"
                            class="video-play-icon"
                            type="logo-youtube"
                            v-if="item.video || idx === 0"
                        />
                    </div>
                    <div class="item-right">
                        <div class="item-title" @click="goTo(item)">
                            {{ item.title }}
                        </div>
                        <div class="item-content">
                            <span class="item-content-title">{{
                                item.startDate
                            }}</span>
                            <span class="item-content-time"
                                >{{ item.beginAt }}-{{ item.endAt }}</span
                            >
                            <span class="item-content-line">|</span>
                            <span class="item-content-title">{{
                                item.presenter
                            }}</span>
                        </div>
                        <div class="item-desc row3-ellipse">
                            {{ item.talkDesc }}
                        </div>
                        <div
                            class="btn-group"
                            :style="{
                                background: item.isReserve
                                    ? 'rgba(0,0,0,0.3)'
                                    : '',
                            }"
                            @click="goTo(item)"
                        >
                            <div>
                                {{
                                    (item.isReserve ? "已完成" : "立即预约")
                                        | language
                                }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="no-date" :class="{ 'show-no-data': isEmpty }" v-else>
                {{ "没有符合筛选条件的数据" | language }}~
            </div>
            <div class="flex"></div>
            <Page
                :total="total"
                :current="pageNum"
                :page-size="pageSize"
                @on-change="handlePageChange"
                style="margin: 40px 0 100px 0"
                show-total
            >
                <span
                    class="ivu-page-total"
                    style="font-size: 16px; color: rgba(0, 0, 0, 0.3)"
                    >{{ "共有" | language }} {{ total }}
                    {{ "条" | language }}</span
                >
            </Page>
        </div>
    </div>
</template>

<script>
import CommonSearchBar from "../../compontents/information/CommonETalkBar.vue";
import dayjs from "dayjs";
export default {
    components: {
        CommonSearchBar,
    },
    data() {
        return {
            list: [],
            keyword: "",
            tag: "",
            year: "",
            pageSize: 10,
            pageNum: 1,
            total: 0,
            isEmpty: false,
        };
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        handleChange(val) {
            this.isEmpty = false;
            let { keyword, year, tag } = val;
            if (
                keyword == this.keyword &&
                year == this.year &&
                tag == this.tag
            ) {
                return;
            }
            this.keyword = keyword;
            this.year = year == 'all'? '':year;
            this.tag = tag;
            this.fetchData();
        },
        async fetchData() {
            let res = await this.$http.get(
                `/information/eTalk?keyword=${this.keyword}&type=${
                    this.tag
                }&year=${this.year}&pageSize=${this.pageSize}&pageNum=${
                    this.pageNum - 1
                }`
            );
            res.result.forEach((el) => {
                el.isReserve = dayjs(el.startDate).isBefore(dayjs(new Date()));
            });
            this.list = res.result;
            this.total = res.count;
            if(res.result.length == 0){
                this.isEmpty = true;
            }
        },
        goTo(item) {
            this.$router.push(`/base/eTalk/detail/${item._id}`);
        },
        //分页
        handlePageChange(page) {
            this.pageNum = page;
            this.fetchData();
        },
    },
};
</script>

<style lang="less" scoped>
.activies {
    .list-container {
        display: flex;
        flex-direction: column;
        min-height: 1000px;

        .list {
            .item {
                padding: 20px 40px;
                margin-top: 30px;
                height: 236px;
                background: rgba(255, 255, 255, 1);
                box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.14);
                display: flex;
                align-items: center;
                .img-container {
                    width: 200px;
                    height: 200px;
                    border-radius: 2px;
                    overflow: hidden;
                    position: relative;
                    flex-shrink: 1;
                    .item-img {
                        width: 100%;
                        height: 100%;
                        border-radius: 2px;
                        object-fit: cover;
                        transition: all ease 0.3s;
                        &:hover {
                            transform: scale(1.1);
                        }
                    }
                }
                .item-right {
                    margin-left: 20px;
                    flex: 1;
                }
                .item-title {
                    margin-bottom: 10px;
                    text-align: left;
                    opacity: 0.9;
                    font-size: 22px;
                    font-weight: 600;
                    color: rgba(0, 0, 0, 0.85);
                    line-height: 32px;
                    cursor: pointer;
                }
                .item-content {
                    margin-bottom: 20px;
                    text-align: left;
                    font-size: 16px;
                    color: #000000;
                    letter-spacing: 0;
                    .item-content-title {
                        opacity: 0.3;
                    }
                    .item-content-time {
                        opacity: 0.3;
                        margin-left: 5px;
                    }
                    .item-content-line {
                        color: #979797;
                        margin: 0 13px;
                    }
                }
                .item-desc {
                    text-align: left;
                    height: 66px;
                    opacity: 0.6;
                    font-size: 16px;
                    color: #000000;
                    letter-spacing: 0;
                    line-height: 29px;
                }
                .btn-group {
                    cursor: pointer;
                    float: right;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 150px;
                    height: 40px;
                    background: #3b95fc;
                    border-radius: 2px;
                    > div {
                        font-size: 18px;
                        color: #ffffff;
                    }
                }
            }
        }
        .no-date {
            width: 200px;
            height: 100px;
            line-height: 100px;
            margin: 60px auto;
            text-align: center;
            font-size: 30px;
            font-weight: bold;
            visibility: hidden;
            opacity: 0;
        }
        .flex {
            flex: 1;
        }
    }
}
@media screen and (max-width: 960px) {
    .activies {
        .list-container {
            .list {
                .item {
                    padding: 10px 20px;
                    .img-container {
                        width: 100px;
                        height: 100px;
                    }
                    .item-right {
                        margin-left: 15px;
                        .item-title {
                            font-size: 18px;
                            line-height: 1;
                            margin-bottom: 10px;
                        }
                        .item-content {
                            margin-bottom: 10px;
                            line-height: 1;
                            font-size: 14px;
                            .item-content-line {
                                margin: 0 5px;
                            }
                        }
                        .item-desc {
                            line-height: 1.5;
                            height: auto;
                        }
                        .btn-group {
                            margin-top: 10px;
                        }
                    }
                }
            }
        }
    }
}
</style>

<style>
.show-no-data {
    visibility: visible !important;
    opacity: 1 !important;
}
</style>
